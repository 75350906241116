@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
@font-face {
  font-family: "burtons";
  src: url("./assets/img/Burtons.otf");
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.AnimeC {
  display: inline-block;
}

@media only screen and (max-width: 900px) {
  #welcome {
    display: flex;
    flex-direction: column;
  }

  #discription {
    margin: 5%;
  }
}

.font-cursive {
  font-family: cursive;
}

.text-4xl {
  font-size: 2.25rem;
}
/* color */
.bg-primary {
  background: #0F172A;
}

.bg-secondery {
  background: #1E293B;
}

.bg-accent {
  background: #7477FF;
}

.text-accent {
  color: #7477FF;
} 
.mobile-nav {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-gray-900 duration-500;
} 

nav li a {
  @apply px-4 py-5 text-lg;
}

nav li a:hover {
  color: #7477FF;
}

.animate-button:hover {
  transform: scaleX(1.5);
  transition: all 0.2s ease-in-out;
}
.animate-button {
  background-color: #4a5568;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.bg-svg {
  background-attachment: scroll;
  background-size: cover;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
  50% {
    width: 100%;
  }
  51% {
    width: 0;
  }
}
@keyframes animate-gradient-left {
  0%, 100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
}

@keyframes animate-gradient-right {
  0%, 100% {
    background-position: 100% 0%;
  }
  25% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  75% {
    background-position: 100% 100%;
  }
}

.animate-gradient-left-right {
  background-size: 200% 200%;
  background-image: linear-gradient(-45deg, #8B5CF6 0%, #EC4899 50%, #F43F5E 100%);
  animation: animate-gradient-left 12s ease infinite, animate-gradient-right 12s ease infinite;
}
.slide-right-and-left {
  background-color: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4,
    0 0 25px #03e9f4,
    0 0 50px #03e9f4,
    0 0 200px #03e9f4;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.slide-up-and-down {
  background: linear-gradient(90deg, transparent, #03e9f4);
}

@keyframes slide-right-and-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-up-and-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.animate-slide-right-and-left {
  animation-name: slide-right-and-left;
}

.animate-slide-up-and-down {
  animation-name: slide-up-and-down;
}

.my-header{
  transform: translateY(0);
transition-property: transform;
transition-duration: 0.3s;
transition-timing-function: ease-in-out;
background-color: #18181b;

}

@media (max-width: 767px) {
  .mobile-font-size {
    font-size: 1em; /* Adjust the font size for mobile */
  }
}
